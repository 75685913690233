<template>
  <div class="order">
    <el-tabs class="consume-tabs" @tab-click="handleClick">
      <el-tab-pane label="分析">
        <analysis></analysis>
      </el-tab-pane>
      <el-tab-pane label="交易">
        <trade></trade>
      </el-tab-pane>
      <el-tab-pane label="资产">
        <assets></assets>
      </el-tab-pane>
      <el-tab-pane label="日志">
        <followUp></followUp>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Trade from './cpns/trade/trade.vue'
import Assets from './cpns/assets/assets.vue'
import followUp from './cpns/follow-up/index.vue'
import analysis from './cpns/analysis/index.vue'
export default defineComponent({
  props: {

  },
  components: {
    Trade,
    Assets,
    followUp,
    analysis
  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
&:deep(.el-tabs__item.is-active) {
  color: rgba(7, 126, 80, 1) !important;
  font-size: 14px;
}
&:deep(.el-tabs__header){
  margin: 0;
}

&:deep(.el-tabs__active-bar) {
  // width: 88px!important;
  height: 3px !important;
  color: rgba(80, 80, 80, 1) !important;
  background-color: rgba(7, 126, 80, 1) !important;
  font-size: 14px;
}

&:deep(.consume-tabs) {
  .el-tabs__active-bar {
    width: 88px !important;

  }

  .el-tabs__item {
    width: 88px;
    text-align: center;
    padding: 0 !important;
  }
}
</style>




