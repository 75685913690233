export const rankProps = {
  value: 'lvId',
  label: 'levelName',
  checkStrictly: true
}

export const genderList = [
  {
    label: '男',
    value: '1'
  },
  {
    label: '女',
    value: '2'
  }
]

export const rules = {
  cash: [{ required: true, message: '输入本金', trigger: 'blur' }],
  cashProm:[{ required: true, message: '输入赠金', trigger: 'blur' }],

}
