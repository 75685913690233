<template>
  <div class="order-tabel">
    <div v-if="category == 0">
      <div class="order-tabels">
        <div class="titles">
          <template :key="item" v-for="item in titleInfoList">
            <div>{{ item }}</div>
          </template>
        </div>
        <div class="order-info-list">
          <div v-if="orderList.length > 0">
            <template :key="item.code" v-for="item in orderList">
              <div class="order-info-item">
                <div class="top">
                  <div class="left">
                    <div class="order-num">订单编号：{{ item.orderNo||'-' }}</div>
                    <div class="buy-time">日志填写时间：{{ item.createTime }}</div>
                  </div>
                  <div class="right"></div>
                </div>
                <div class="bottom">
                  <div class="teacher">
                    <span>
                      {{ item.date }}
                    </span>
                  </div>
                  <div>
                    {{ item.empName }}
                  </div>
                  <div>
                    {{ item.empName }}
                  </div>
                  <div>
                    {{ item.userAttribute==1?'新客':item.userAttribute==2?'续卡':'' }}
                  </div>
                  <div style="display: flex;flex-direction: column;justify-content: center;">
                    <div>储值卡余额：¥{{ item.userCardBalance||0 }}</div>
                    <div>次卡余额：{{ item.userServeBalance||0 }}次</div>
                  </div>
                  <div>
                    {{item.exploringNeeds}}
                  </div>
                  <div>
                    {{item.matchingScheme}}
                  </div>
                  <div>
                    {{item.provideSolutions}}
                  </div>
                  <div>
                    {{item.successfullyReceivedPayment}}
                  </div>
                  <div>
                    {{item.replay}}
                  </div>
                  <div>
                    <el-button @click="handleClick(item.orderId)" size="small" plain :disabled="!item.orderId">详情</el-button>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div v-else class="empty">
            <el-empty description="暂无相关数据"></el-empty>
          </div>
        </div>
      </div>
    </div>
    <div v-if="category == 1">
      <el-form
        label-width="100px"
        :model="planDetails"
        style="max-width: 460px"
        label-suffix="："
      >
        <el-form-item label="诊断时间">
          <el-input v-model="planDetails.date"/>
        </el-form-item>
        <el-form-item label="诊断人">
          <el-input v-model="planDetails.createUser" />
        </el-form-item>
        <el-form-item label="主诉">
          <el-input v-model="planDetails.mainDemands" />
        </el-form-item>
        <el-form-item label="现病史">
          <el-input v-model="planDetails.currentMedicalHistory" />
        </el-form-item>
        <el-form-item label="既往史">
          <el-input v-model="planDetails.pastHistory" />
        </el-form-item>
        <el-form-item label="查体">
          <el-input v-model="planDetails.physicalExamination" />
        </el-form-item>
        <el-form-item label="辅助检查">
          <el-input v-model="planDetails.auxiliaryExamination" />
        </el-form-item>
        <el-form-item label="诊断结果">
          <el-input v-model="planDetails.diagnosticResults" />
        </el-form-item>
      </el-form>
      <div v-if="planDetails.stageVoList&&planDetails.stageVoList.length">
        <div class="title">方案：</div>
        <el-table :data="planDetails.stageVoList" border style="width: 100%">
          <el-table-column type="index" align="center" width="80" label="序号"/>
          <el-table-column prop="name" label="病理阶段" width="150" />
          <el-table-column prop="treatmentPrinciples" label="治疗原则" width="150" />
          <el-table-column prop="massageParts" label="推拿部位" width="150" />
          <el-table-column prop="massageMuscles" label="推拿的肌肉" width="150" />
          <el-table-column prop="serveName" label="使用理疗" width="150" />
          <el-table-column prop="planCount" label="处理次数" width="100" />
          <el-table-column prop="cycle" label="处理周期">
            <template #default="scope">
              <p v-if="scope.row.cycle==1">隔天</p>
              <p v-if="scope.row.cycle==2">3天</p>
              <p v-if="scope.row.cycle==3">7天</p>
              <p v-if="scope.row.cycle==4">10天</p>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="margin-top:30px" v-if="planDetails.followListVoList&&planDetails.followListVoList.length">
        <div class="title">治疗跟进：</div>
        <el-table :data="planDetails.followListVoList" border style="width: 100%">
          <el-table-column type="index" align="center" width="80" label="序号"/>
          <el-table-column prop="createTime" label="服务时间" width="165" />
          <el-table-column prop="createUser" label="服务人" width="100" />
          <el-table-column prop="stage" label="病理阶段" width="100" />
          <el-table-column prop="massageParts" label="推拿部位" width="80" />
          <el-table-column prop="massageMuscles" label="推拿肌肉" width="100" />
          <el-table-column prop="serveName" label="使用理疗" width="100" />
          <el-table-column prop="count" label="处理次数" width="100"/>
          <el-table-column prop="curativeEffect" label="疗效判断" width="100"/>
          <el-table-column prop="remark" label="备注"/>
        </el-table>
      </div>
      <div v-if="planDetails.understandingUsers" class="title" style="margin-top:30px;padding-bottom:30px;">了解用户：{{ planDetails.understandingUsers }}</div>
    </div>
    <div v-if="category == 2">
      <div class="order-tabels">
        <div class="titles">
          <template :key="item" v-for="item in titleInfoListTwo">
            <div>{{ item }}</div>
          </template>
        </div>
        <div class="order-info-list">
          <div v-if="orderList.length > 0">
            <template :key="item.code" v-for="item in orderList">
              <div class="order-info-item">
                <div class="top">
                  <div class="left">
                    <div class="order-num">订单编号：{{ item.orderNo }}</div>
                    <div class="buy-time">填写时间：{{ item.createTime }}</div>
                  </div>
                  <div class="right"></div>
                </div>
                <div class="bottom">
                  <div class="teacher">
                    <span>
                      {{ item.createUser }}
                    </span>
                  </div>
                  <div>
                    {{ item.mainDemands }}
                  </div>
                  <div>
                    {{ item.massageMuscles }}
                  </div>
                  <div>
                    <p v-if="item.customerIdentification==1">认同</p>
                    <p v-if="item.customerIdentification==2">不认同</p>
                    <p v-if="item.customerIdentification==3">客户不懂</p>
                  </div>
                  <div>
                    {{ item.curativeEffect}}
                  </div>
                  <div>
                    {{item.reverseReservationDate}}
                  </div>
                  <div>
                    {{item.understandingUsers}}
                  </div>
                  <div>
                    {{item.remark}}
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div v-else class="empty">
            <el-empty description="暂无相关数据"></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch ,computed} from 'vue'
import { useState } from '@/hooks'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {
    titleList: {
      type: Array,
      default() {
        return []
      }
    },
    dataTotal: {
      type: Number,
      default: 0
    },
    orderList: {
      type: Array,
      default() {
        return []
      }
    },
    category: {
      type: Number,
      default: 0
    },
    page: {
      type: Object,
      default: () => ({ currentPage: 1, pageSize: 10 })
    },
    planDetail: {
      type: Object,
      default() {
        return {}
      }
    },


  },
  components: {
  },
  emits: [],
  setup(props, { emit }) {
    const router = useRouter()
    const store = useStore()
    const pageInfo = ref({ currentPage: 1, pageSize: 10 })
    const titleInfoList = computed(() => store.state.order.titleInfoList)
    const titleInfoListTwo = computed(() => store.state.order.titleInfoListTwo)
    const planDetails = ref({})
    watch(pageInfo, (newValue) => {
      // console.log(newValue);
    }, {
      deep: true
    })
    watch(() => props.planDetail, (newValue) => {
      planDetails.value = newValue
    }, {
      deep: true
    })

    const handleClick = (orderId) => {
      router.push({
        path: '/main/order/detail',
        query: {
          id: orderId,
          type:1
        }
      })
    }
    return {
      handleClick,
      pageInfo,
      planDetails,
      titleInfoList,
      titleInfoListTwo
    }

  }
})
</script>

<style scoped lang="less">
.order-tabel {
  margin-top: 20px;
  font-size: 12px;

  .title {
    padding: 10px;
    font-size: 14px;
  }

  .order-info-list {
    .order-info-item {
      margin: 10px 0;

      .top {
        padding: 0 10px;
        display: flex;
        color: rgba(80, 80, 80, 1);
        background-color: rgba(239, 239, 239, 1);
        font-size: 12px;
        height: 35px;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          flex: 4;

          & > div {
            margin-right: 20px;
          }

          .buy-time {
            margin-right: 20px;
          }
        }

        .right {
          flex: 1;
          font-size: 12px;
          text-align: center;
          color: rgb(102, 177, 255);
          cursor: pointer;
        }
      }

      .bottom {
        display: flex;

        .opt-show {
          color: rgb(64, 158, 255);
          cursor: pointer;
        }

        .but-num,
        .price {
          flex-direction: column;

          .price-value,
          .price-value {
            width: 100%;
            line-height: 30px;
            // text-align: center;
            border-bottom: 1px solid var(--borderColor);
          }

          .price-value:last-child,
          .price-value:last-child {
            border-bottom: none !important;
          }
        }

        & > div {
          padding: 10px;
          display: flex;
          flex: 1;
          // justify-content: center;
          border-right: 1px solid var(--borderColor);
          border-bottom: 1px solid var(--borderColor);
          align-items: center;
          white-space: pre-wrap;
          color: rgba(80, 80, 80, 1);
          font-size: 12px;
          min-height: 60px;
          img{
            width: 32px;
            height: 32px;
            margin-right: 10px;
          }
        }

        .avatar {
          border-left: 1px solid var(--borderColor);

          img,
          .avatar-zhanwei {
            width: 32px;
            height: 32px;
            margin-right: 10px;
          }
        }

        .goods_name {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .el-pagination {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
  &:deep(.el-table) {
    thead.is-group th.el-table__cell{
      background-color: rgba(239, 239, 239, 1) !important;
      .cell {
        color: rgba(80, 80, 80, 1) !important;
        font-size: 14px !important;
        font-weight: 500 !important;
      }
    }
    .el-table__header-wrapper {
      .el-table__cell {

        background-color: rgba(239, 239, 239, 1);
        // padding: 9px 0 !important;
        // border-right:1px solid rgb(223, 223, 223);
        border:1px solid rgb(223, 223, 223);
        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 12px !important;
          font-weight: 400 !important;
        }
      }
    }
  }
  
  .borders{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    p{
      border-bottom: 1px solid #ebeef5;
      line-height: 28px;
    }
    p:last-child{
      border: none !important;
    }
  }
}
.ellipsis-text{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}
.order-tabels {
  margin-top: 20px;
  font-size: 12px;

  .user-name {
    cursor: pointer;
    color: rgb(64, 158, 255) !important;
  }

  .state {
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      display: inline-block;
      margin-bottom: 3px;
    }
  }

  .buy-shop {
    flex-direction: column;
    justify-content: center;

    & > div {
      margin-bottom: 4px;
    }
  }

  .teacher {
    flex-direction: column;
    display: flex;
    // align-items: center;
    justify-content: center;
    align-items: flex-start !important;

    & > div {
      margin: 6px 0;
    }
  }

  .handle {
    display: flex;
    flex-wrap: wrap;

    &:deep(.el-button) {
      margin: 4px 4px;
    }
  }

  .titles {
    display: flex;
    color: rgba(80, 80, 80, 1);
    background-color: rgba(239, 239, 239, 1);
    height: 48px;
    align-items: center;
    padding: 0 10px;
    // justify-content: space-between;

    div {
      flex: 1;
      // font-weight: 550;
      // text-align: center;
    }
  }

  .order-info-list {
    // padding: 0 10px;
    .order-info-item {
      margin: 10px 0;

      .top {
        padding: 0 10px;
        display: flex;
        color: rgba(80, 80, 80, 1);
        background-color: rgba(239, 239, 239, 1);
        font-size: 12px;
        height: 35px;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          color: #666666;

          .buy-time {
            margin-right: 20px;
          }
        }

        .right {
          color: rgba(42, 130, 228, 1);
          font-size: 12px;
          cursor: pointer;
        }
      }

      .bottom {
        display: flex;

        .avatar-price-buynum {
          display: flex;
          flex-direction: column;
          flex: 3;
          align-items: stretch;
          padding-left: 0px;

          .avatar-price-buynum-item {
            border-top: 1px solid var(--borderColor);
            display: flex;
            flex: 1;

            .avatar {
              padding: 0 10px;
            }

            .but-num {
              padding: 0 10px;
            }

            .price {
              div {
                width: 100%;
                padding: 0 10px;
              }
            }

            & > div {
              flex: 1;
            }
          }

          .price,
          .but-num {
            display: flex;
            align-items: center;

            & > div {
              padding: 10px 0;
            }
          }
        }

        .price {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          border-left: 1px solid var(--borderColor);
          border-right: 1px solid var(--borderColor);
          // align-items: center;
          // justify-content: center;
        }

        .price,
        .total-price {
          color: #ff6600;
        }

        .total-price {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .pay-type {
          font-size: 12px;
          margin-top: 2px;
        }

        & > div {
          display: flex;
          flex: 1;
          padding-left: 10px;
          // justify-content: center;
          border-right: 1px solid var(--borderColor);
          border-bottom: 1px solid var(--borderColor);
          align-items: center;
          color: rgba(80, 80, 80, 1);
          font-size: 12px;
          min-height: 55px;
        }
      }
    }
  }

  .el-pagination {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>




