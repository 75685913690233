<template>
  <div class="coupon">
    <div class="title">
      <el-select v-model="available" placeholder="请选择" @change="handleChange" clearable>
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <span></span>
      <span class="other">优惠券 ({{ couponList.length }})</span>
    </div>
    <div v-if="couponList.length == 0" class="empty-state-list">
      <el-empty description="暂无优惠券数据" />
    </div>
    <coupon-item v-else :couponList="couponList"></coupon-item>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import CouponItem from './cpns/coupon-item/coupon-item.vue'
import { useState } from '@/hooks'
import { getWebUserCouponList } from '@/service/main/customer'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: {

  },
  components: {
    CouponItem
  },
  setup() {
    const route = useRoute()
    const couponList = ref([])
    const options = [
      {
        value: 1,
        label: '未使用'
      },
      {
        value: 2,
        label: '已使用'
      },
      {
        value: 3,
        label: '已过期'
      },
      {
        value: 4,
        label: '已作废'
      },
      {
        value: 5,
        label: '待领取'
      }
    ]
    const available = ref(1)
    const handleChange = val => {
      initPage()
    }
    const initPage = async () => {
      const res = await getWebUserCouponList({userId:route.query.userId,current:1,size:-1,status: available.value||undefined})
      couponList.value=res.data.list
    }
    initPage()

    return {
      couponList,
      options,
      available,
      handleChange
    }

  }
})
</script>

<style scoped lang="less">
.coupon {
  .title {
    padding-left: 16px;
    position: relative;
    margin: 30px 0 10px 0px;
    display: flex;
    justify-content: space-between;

    .other {
      color: rgba(42, 130, 228, 1);
      font-size: 14px;
    }
  }
}
</style>
