<template>
  <div class="trade">
    <el-tabs type="card" class="tabs" @tab-click="handleClick">
      <el-tab-pane label="销售日志">
        <top-content
          @topContentSearch="topContentSearch"
          @searchChange="searchChange"
          @topContentClearClick = "topContentClearClick"
        ></top-content>
        <salelog-list
          :category="0"
          :orderList="saleLogDataList"
          :titleList="titleList"
        ></salelog-list>
        <div class="pagination">
          <el-pagination
            v-model:currentPage="ruleForm.saleLogCurrent"
            v-model:page-size="ruleForm.saleLogPagesize"
            :page-sizes="[10, 20, 30, 40]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Number(saleLogListCount)"
            @size-change="handleSizeChange($event, 0)"
            @current-change="handleCurrentChange($event, 0)"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="治疗方案">
        <div v-if="planList&&planList.length">
          <div>
            <el-radio-group v-model="palnId" size="large" @change="changePlan">
              <el-radio-button v-for="(item,index) in planList" :key="index" :label="item.id">{{ item.name }}</el-radio-button>
            </el-radio-group>
          </div>
          <salelog-list
            :category="1"
            :planDetail="planDetail"
          ></salelog-list>
      </div>
      <div v-else class="empty">
        <el-empty description="暂无相关数据"></el-empty>
      </div>
      </el-tab-pane>
      <el-tab-pane label="放松日志">
        <serve-content
          @topContentSearch="serveContentSearch"
          @searchChange="searchServeChange"
          @serveContentClearClick="serveContentClearClick"
        ></serve-content>
        <salelog-list
          :category="2"
          :orderList="serveLogDataList"
        ></salelog-list>
        <div class="pagination">
          <el-pagination
            v-model:currentPage="ruleForm.serveLogCurrent"
            v-model:page-size="ruleForm.serveLogPagesize"
            :page-sizes="[10, 20, 30, 40]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Number(serveLogListCount)"
            @size-change="handleSizeChange($event, 2)"
            @current-change="handleCurrentChange($event, 2)"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import TopContent from './cpns/top-content/top-content.vue'
import ServeContent from './cpns/top-content/serve-content.vue'
import salelogList from './cpns/order-list/order-list.vue'
import { useState } from '@/hooks'
import planDetailVue from '@/views/main/commission-settings/detail/cpns/plan-detail/plan-detail.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { COMPANY_ID } from '@/common/constant'
import { getCustomerSaleLog , getTreatmentPlanList,getTreatmentPlan,getWebServeLogList} from '@/service/main/customer'
import { tips } from '@/utils/tips'
import dayjs from 'dayjs'

export default defineComponent({
  props: {

  },
  components: {
    TopContent,
    ServeContent,
    salelogList
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const orderListState = useState('customer', ['tradeOrderList', 'titleList', 'followOrderList', 'commentTitleList', 'commentOrderList'])

    const userId = computed(() => route.query.userId)
    const saleLogListCount = ref(0)
    const saleLogDataList = ref([])
    const planList = ref([])
    const palnId = ref(null)
    const planDetail = ref(null)
    const serveLogDataList = ref([])
    const serveLogListCount = ref(0)

    const ruleForm = reactive({
      saleLogCurrent: 1,
      serveLogCurrent: 1,
      saleLogPagesize: 10,
      serveLogPagesize: 10,
      date:[],
      serveDate:[]
    })


    const getSaleLog = async() => {
      let params = {
        current:ruleForm.saleLogCurrent,
        size:ruleForm.saleLogPagesize,
        startDate:ruleForm.date&&ruleForm.date.length?ruleForm.date[0]:undefined,
        endDate:ruleForm.date&&ruleForm.date.length?ruleForm.date[1]:undefined,
        userId:userId.value
      }
      let data = await getCustomerSaleLog(params)
      saleLogDataList.value = data.data.list
      saleLogListCount.value = data.data.total
    }
    getSaleLog()

    const getPlanList = async() => {
      let data = await getTreatmentPlanList(userId.value)
      let list = data.data||[];
      list.map((item,index)=>{
        item.name = item.status==3?"方案"+toChineseNum(index+1)+"(已完成)":"方案"+toChineseNum(index+1)
      })
      planList.value = list
      if (list.length<=0) {
        return
      }
      palnId.value = list[0].id
      getPlanDetail()
    }
    getPlanList()

    const getPlanDetail = async() =>{
      let data = await getTreatmentPlan(palnId.value)
      planDetail.value = data.data
    }

    const toChineseNum = (number) => {
      const chineseNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
      const chineseUnit = ["", "十", "百", "千", "万", "亿"];
      let numStr = number.toString();
      let len = numStr.length;
      let str = "";
      for (let i = 0; i < len; i++) {
        str += chineseNum[parseInt(numStr[i])] + chineseUnit[len - 1 - i];
      }
      str = str.replace(/零[十百千]/g, "零");
      str = str.replace(/零+/g, "零");
      str = str.replace(/^零+/, "");
      str = str.replace(/零+$/, "");
      if (str[str.length - 1] === "零") {
        str = str.slice(0, -1);
      }
      if (str.length>1&& str[0]=== "一") {
        str = str.slice(1, str.length );
      }
      return str;
    }
    const changePlan = (e) =>{
      getPlanDetail()
    }
    const getServeLogList = async() => {
      let params = {
        current:ruleForm.serveLogCurrent,
        size:ruleForm.serveLogPagesize,
        startDate:ruleForm.serveDate&&ruleForm.serveDate.length?ruleForm.serveDate[0]:undefined,
        endDate:ruleForm.serveDate&&ruleForm.serveDate.length?ruleForm.serveDate[1]:undefined,
        userId:userId.value
      }
      let data = await getWebServeLogList(params)
      data.data.list.map(item=>{
        item.massageMuscles=(JSON.parse(item.massageMuscles)).join('，')
        item.treatmentPrinciples=(JSON.parse(item.treatmentPrinciples)).join('，')
      })
      serveLogDataList.value = data.data.list
      serveLogListCount.value = data.data.total
    }
    getServeLogList()

    const searchChange = item => {
      ruleForm.date = item.date||undefined
    }


    const topContentSearch = () => {
      getSaleLog()
    }
    const topContentClearClick = () =>{
      ruleForm.date = undefined
      getSaleLog()
    }
    const serveContentClearClick = () =>{
      ruleForm.serveDate = undefined
      getServeLogList()
    }
    const serveContentSearch = () => {
      getServeLogList()
    }
    const searchServeChange= item => {
      ruleForm.serveDate = item.date||undefined
    }

    const handleCurrentChange = (page, type) => {
      if (type == 2) {
        ruleForm.serveLogCurrent = page
        getServeLogList()
      } else {
        ruleForm.saleLogCurrent = page
        getSaleLog()
      }

    }

    const handleSizeChange = (pagesize, type) => {
      if (type == 2) {
        ruleForm.serveLogPagesize = pagesize
        getServeLogList()
      } else {
        ruleForm.saleLogPagesize = pagesize
        getSaleLog()
      }

    }

    return {
      handleSizeChange,
      handleCurrentChange,
      ...orderListState,
      ruleForm,
      COMPANY_ID,
      saleLogListCount,
      saleLogDataList,
      searchChange,
      topContentSearch,
      planList,
      palnId,
      changePlan,
      planDetail,
      getPlanDetail,
      serveLogDataList,
      serveLogListCount,
      serveContentSearch,
      searchServeChange,
      topContentClearClick,
      serveContentClearClick
    }

  }
})
</script>

<style scoped lang="less">
.trade {
  margin-top: 30px;
  &:deep(.el-tabs__item) {
    background-color: rgb(229, 229, 229);
  }
  &:deep(.el-tabs__item.is-active) {
    color: rgba(80, 80, 80, 1) !important;
    font-size: 14px;
    border-bottom: 1px solid rgba(228, 231, 237, 1);
    background-color: #fff;
  }

  .pagination {
    text-align: right;
  }
  &:deep(.el-radio-group){
    display: flex !important;
    width: 100% !important;
    overflow-x: auto;
  }
}
</style>
