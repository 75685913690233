<template>
  <div class="dialog-form">
    <el-dialog v-if="isShow" v-model="isShow" title="用户标签管理" width="30%">
      <div class="top-form">
        <el-input size="small" v-model.trim="tagInput" placeholder="请输入标签名称" />
        <el-button :disabled="tagInput.length == 0" color="#409EFF" size="small" class="add-btn" type="primary"
          @click="handleAddTagClick" plain>新增标签
        </el-button>
      </div>
      <div class="tag-list">
        <template v-if="tagValue != null && tagValue.length > 0">
          <template :key="elem" v-for="elem in tagValue.split(',')">
            <el-tag @dblclick="handleCheckBoxDbclick(elem)" @close="handleTagClose(elem)" closable>
              {{ elem }}
            </el-tag>
          </template>
        </template>
      </div>
      <template #footer>
        <!-- <el-button size="small" color="#409EFF" @click="saveTagClick" type="primary">保存</el-button> -->
      </template>
    </el-dialog>
    <page-dialog :width="30" title="更改标签" @changeDialogVisible="changeUpdateNameDialogVisible"
      :dialogVisible="dialogUpdateNameVisible">
      <div class="update-name-form">
        <el-input size="small" v-model.trim="updateCategoryNameValue" placeholder="请输入标签名称" />
        <el-button color="#409EFF" :disabled="updateCategoryNameValue.length == 0" plain class="sure-btn" size="small"
          type="primary" @click="handleUpdateTagNameClick">
          确定</el-button>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import { useState } from '@/hooks'
import { rankProps, genderList } from './config'
import dayjs from 'dayjs'
import { editUserTag, addUserTag, updateUserTag } from '@/service/main/customer'
import { tips } from '@/utils/tips'
import pageDialog from '@/components/page-dialog/page-dialog.vue'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    userInfo: {
      type: Number,
      default: -1
    }
  },
  components: {
    UploadImg,
    pageDialog

  },
  emits: ['changeDialogVisible', 'refreshUserInfo'],
  setup(props, { emit }) {
    const store = useStore()
    const isShow = ref(props.dialogVisible)

    watch(() => props.dialogVisible, (newValue) => {
      isShow.value = newValue
    }, {
      deep: true
    })

    watch(isShow, (newValue) => {
      emit('changeDialogVisible', newValue)
    })

    watch(() => props.userInfo, (n) => {
      if (n != null) {
        tagValue.value = n.tagList
        // console.log(n);
      }
    }, {
      deep: true
    })


    const tagValue = ref('')
    const tagInput = ref('')
    const handleAddTagClick = async () => {
      const res = await addUserTag(COMPANY_ID ,props.userInfo.userId, tagInput.value)

      if (res.code == 0) {
        tips(res)
        emit('refreshUserInfo')
        tagInput.value = ''
      }
    }

    const dialogUpdateNameVisible = ref(false)
    const changeUpdateNameDialogVisible = flag => {
      dialogUpdateNameVisible.value = flag
    }
    const updateCategoryNameValue = ref('')
    const oldCategoryValue = ref('')

    const handleCheckBoxDbclick = (item) => {
      // console.log(item);
      updateCategoryNameValue.value = item
      oldCategoryValue.value = item
      dialogUpdateNameVisible.value = true
    }

    const handleUpdateTagNameClick = async () => {
      const res = await updateUserTag(
        props.userInfo.userId,
        oldCategoryValue.value,
        updateCategoryNameValue.value,
      )

      if (res.code == 0) {
        tips(res)
        emit('refreshUserInfo')
        dialogUpdateNameVisible.value = false
      }
    }


    const handleTagClose = async (item) => {
      let tags = tagValue.value.split(',').filter(ele => ele != item)
      const res = await editUserTag({
        companyId: COMPANY_ID,
        userId: props.userInfo.userId,
        tag: tags.join()
      })
      if (res.code == 0) {
        tips(res)
        emit('refreshUserInfo')
      }
    }



    const initPage = () => {

    }
    initPage()


    return {
      handleAddTagClick,
      isShow,
      COMPANY_ID,
      rankProps,
      genderList,
      dayjs,
      tagValue,
      tagInput,
      handleTagClose,
      pageDialog,
      dialogUpdateNameVisible,
      changeUpdateNameDialogVisible,
      handleCheckBoxDbclick,
      updateCategoryNameValue,
      handleUpdateTagNameClick,
      oldCategoryValue


    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  .update-name-form {
    display: flex;

    .sure-btn {
      margin-left: 10px;
    }
  }

  .top-form {
    display: flex;

    .add-btn {
      margin-left: 10px;
    }
  }

  .tag-list {
    margin-top: 20px;

    &>span {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }

  &:deep(.el-form-item__label) {
    font-size: 12px;
  }

  .use-time {
    line-height: 45px;
    background-color: rgb(224, 241, 240);
    padding: 0 10px;
  }

  .handsel {
    .title {
      margin: 20px 0 10px 0;
      color: rgb(181, 181, 181);
    }

    .item {
      display: flex;
      justify-content: space-between;
      line-height: 40px;
    }
  }

  &:deep(.el-scrollbar) {
    padding-right: 20px;
  }



}
</style>
