<template>
  <div class="assets">
    <el-tabs type="card" class="tabs">
      <el-tab-pane label="卡包">
        <card></card>
      </el-tab-pane>
      <el-tab-pane label="优惠券">
        <coupon></coupon>
      </el-tab-pane>
      <el-tab-pane label="积分">
        <integral></integral>
      </el-tab-pane>
      <!-- <el-tab-pane label="Task">
        <arrears></arrears>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Card from './cpns/card/card.vue'
import Coupon from './cpns/coupon/coupon.vue'
import Integral from './cpns/integral/integral.vue'
import Arrears from './cpns/arrears/arrears.vue'
export default defineComponent({
  props: {

  },
  components: {
    Card,
    Coupon,
    Integral,
    Arrears
  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.assets {
  padding-bottom: 20px;
  font-size: 12px;
  margin-top: 30px;

  &:deep(.el-tabs__item) {
    background-color: rgb(229, 229, 229);
  }

  &:deep(.el-tabs__item.is-active) {
    color: rgba(80, 80, 80, 1) !important;
    font-size: 14px;
    border-bottom: 1px solid rgba(228, 231, 237, 1);
    background-color: #fff;
  }
}
</style>
