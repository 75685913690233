<template>
    <div class="xiaoshoue">
      <div id="shop" :style="{ width: '100%', height: '450px' }"></div>
      <div class="title">店消费排行</div>
    </div>
  </template>
  
  <script lang="js">
  import { defineComponent, reactive, ref, onMounted, nextTick,watch } from 'vue'
  import * as echarts from 'echarts'
  
  export default defineComponent({
    props: {
      dataList:{
        type: Array,
        default() {
          return []
        }
      }
    },
    components: {
  
    },
    setup(props,{ emit }) {
      const nameData = ref([])
      const valueData = ref([])
      watch(() => props.dataList, (newValue) => {
        if (newValue) {
            nameData.value=newValue.map(item=>{
                return item.name
            })
            valueData.value=newValue.map(item=>{
                return item.count
            })
            nextTick(()=>{
                getData()
            })
        }
      }, {
        deep: true,
        immediate:true
      })
      const getData = () =>{
        let leftChart = echarts.init(document.getElementById("shop"));
        leftChart.setOption({
            grid: {
                left: "2%",
                right: "2%",
                bottom: "10%",
                containLabel: true,
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "cross",
                    crossStyle: {
                        color: "#999",
                    },
                },
            },
            xAxis: [
                {
                    type: "value",
                    // data: nameData.value,
                    axisPointer: {
                        type: "shadow",
                    },
                    min: 0
                },
            ],
            yAxis: [
                {
                    type: "category",
                    inverse: true,
                    data: nameData.value
                },
            ],
            series: [
                {
                    name: "消费次数",
                    type: "bar",
                    data: valueData.value,
                    barWidth: 30,
                    itemStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        { offset: 0, color: "#83bff6" },
                        { offset: 0.5, color: "#188df0" },
                        { offset: 1, color: "#188df0" },
                      ]),
                    },
                    label:{
                      show:true,
                      position:'right',
                      formatter:function(data){
                        return data.value
                      }
                    }
                },
            ],
            graphic: { // 无数据显示设置
            type: 'text', // 类型：文本
            left: 'center',
            top: 'middle',
            silent: true, // 不响应事件
            invisible: valueData.value.length > 0, // 有数据就隐藏
            style: {
              fill: '#9d9d9d',
              fontWeight: 'bold',
              text: '暂无数据',
              fontFamily: 'Microsoft YaHei',
              fontSize: '25'
            }
          },
        })
      }
  
      return {
  
      }
  
    }
  })
  </script>
  
  <style scoped lang="less">
  .xiaoshoue {
    margin: 0 auto 20px;
    // background-color: #fff;
    width: 500px;
  
    .title {
      // line-height: 40px;
      font-size: 24px;
      font-weight: 500;
      text-align: center;
    }
    #shop{
      margin: 0 auto;
    }
  }
  </style>
  
  
  
  
  