export const breadcrumbList = [
  {
    name: '订单管理',
    path: ''
  },
  {
    name: '订单列表',
    path: ''
  }
]

export const orderCategory = [
  {
    label: '预约订单',
    value:'1'
  },
  {
    label: '卡项订单',
    value:'2'
  }
]

export const payCategory = [
  {
    label: '在线支付直接支付',
    value:'1'
  },
  {
    label: '聚合支付',
    value:'2'
  },
  {
    label: '有限次卡支付',
    value:'3'
  },
  {
    label: '无限次卡支付',
    value:'4'
  },
  {
    label: '充值卡订单',
    value:'6'
  },
  {
    label: '储值卡支付',
    value:'7'
  },
]



export const orderStatusList = [
  {
    label: '待到店',
    value:'1'
  },
  {
    label: '已到店',
    value:'2'
  },
  {
    label: '已开单',
    value:'3'
  },
  {
    label: '已完成',
    value:'4'
  },
  {
    label: '已取消',
    value:'5'
  },
  {
    label: '已退款',
    value:'6'
  },
]


export const paystatusList = [
  {
    label: '待付款',
    value:'1'
  },
  {
    label: '已付款',
    value:'2'
  },
  {
    label: '已取消',
    value:'3'
  }
]


export const sourceCategory = [
  {
    label: '小程序',
    value:'1'
  },
  {
    label: '商户扫码',
    value:'2'
  }
]



