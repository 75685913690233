<template>
  <div class="dialog-form">
    <el-dialog v-if="isShow" v-model="isShow" title="更新标签" width="30%">
      <el-form
        label-position="left"
        ref="ruleFormRef"
        :model="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item label="用于标签:" prop="name">
          <el-input v-model="tagValue" placeholder="添加个性标签" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" color="#409EFF" @click="saveTagClick" type="primary"
          >保存</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import { useState } from '@/hooks'
import { rankProps, genderList } from './config'
import dayjs from 'dayjs'
import { editUserTag } from '@/service/main/customer'
import { tips } from '@/utils/tips'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    userInfo: {
      type: Number,
      default: -1
    }
  },
  components: {
    UploadImg

  },
  emits: ['changeDialogVisible', 'refreshUserInfo'],
  setup(props, { emit }) {
    const store = useStore()
    const isShow = ref(props.dialogVisible)

    watch(() => props.dialogVisible, (newValue) => {
      isShow.value = newValue
    }, {
      deep: true
    })

    watch(isShow, (newValue) => {
      emit('changeDialogVisible', newValue)
    })

    watch(() => props.userInfo, (n) => {
      if (n != null) {
        tagValue.value = n.tagList
        // console.log(n);
      }
    }, {
      deep: true
    })


    const tagValue = ref('')
    const saveTagClick = async () => {
      // console.log(tagValue.value);
      let tag = tagValue.value
      tag = tag.replace(/，/g, ",")
      const res = await editUserTag({
        companyId: COMPANY_ID,
        userId: props.userInfo.userId,
        tag
      })
      if (res.code == 0) {
        tips(res)
        emit('refreshUserInfo')
        isShow.value = false
      }




    }



    const initPage = () => {

    }
    initPage()


    return {
      saveTagClick,
      isShow,
      COMPANY_ID,
      rankProps,
      genderList,
      dayjs,
      tagValue


    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  &:deep(.el-form-item__label) {
    font-size: 12px;
  }

  .use-time {
    line-height: 45px;
    background-color: rgb(224, 241, 240);
    padding: 0 10px;
  }

  .handsel {
    .title {
      margin: 20px 0 10px 0;
      color: rgb(181, 181, 181);
    }

    .item {
      display: flex;
      justify-content: space-between;
      line-height: 40px;
    }
  }

  &:deep(.el-scrollbar) {
    padding-right: 20px;
  }
}
</style>
