<template>
  <div class="integral">
    <div class="title">
      <div class="left"></div>
      <div class="right">
        <span>积分操作</span>
        <span>积分明细</span>
      </div>
    </div>
    <div class="main-content">
      <div class="left">
        <div class="top">可用积分</div>
        <div class="bottom">0</div>
      </div>
      <div class="right">
        <div class="integral-item">
          <div>总积分</div>
          <div>0</div>
        </div>
        <div class="integral-item">
          <div>冻结中</div>
          <div>0</div>
        </div>
        <div class="integral-item">
          <div>已消耗</div>
          <div>0</div>
        </div>
        <div class="integral-item">
          <div>已消耗</div>
          <div>0</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.integral {
  .title {
    padding-left: 16px;
    position: relative;
    margin: 30px 0px;
    display: flex;
    justify-content: space-between;

    .right {
      span {
        margin: 0 6px;
        color: rgba(42, 130, 228, 1);
        font-size: 14px;
      }
    }

  }
  .main-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    font-size: 14px;
   border: 2px solid rgb(239, 239, 239);
    margin: 0 10px;
    padding: 20px;
    min-height: 118px;
    .left {
      flex: 2;
      display: flex;
      flex-direction: column;
      // padding-right: 30px;
      border-right: 1px solid var(--borderColor);
      .top {
        color: rgba(42, 130, 228, 1);
        font-size: 14px;
        margin-bottom: 16px;
        text-align: center;
      }
      .bottom {
        color: rgba(0, 0, 0, 1);
        font-size: 20px;
        text-align: center;
      }
    }
    .right {
      flex: 6;
      display: flex;
      // padding-left: 30px;
      .integral-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        & > div:nth-child(1) {
          color: rgba(80, 80, 80, 1);
          font-size: 14px;
          margin-bottom: 16px;
        }
        & > div:nth-child(2) {
          color: rgba(0, 0, 0, 1);
          font-size: 20px;
        }
      }
    }
  }
}
</style>




