<template>
  <div class="arrears">
    <div class="title">
      <div class="left">欠款</div>
      <div class="right">
        <span>还款记录</span>
        <span>去还款</span>
      </div>
    </div>
    <div class="main-content">
      <div class="arrears-item">
        <div>待还款(元)</div>
        <div>0.00</div>
      </div>
      <div class="arrears-item">
        <div>已还款(元)</div>
        <div>0.00</div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.arrears {
  .title {
    padding-left: 16px;
    position: relative;
    margin: 30px 0px;
    display: flex;
    justify-content: space-between;

    .right {
      span {
        margin: 0 6px;
        color: rgba(42, 130, 228, 1);
        font-size: 14px;
      }
    }
    &::before {
      width: 6px;
      height: 22px;
      color: rgba(80, 80, 80, 1);
      font-size: 14px;
      content: "";
      position: absolute;
      top: -2px;
      left: 0;
      background-color: rgba(255, 141, 26, 1);
    }
  }

  .main-content {
    display: flex;
    min-height: 118px;
    align-items: center;
    margin: 0 10px 10px 10px;
    color: rgba(80, 80, 80, 1);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    font-size: 14px;
    line-height: 150%;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
    &>div:nth-child(1){
        border-right: 1px solid var(--borderColor);
    }
    .arrears-item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      & > div:nth-child(1) {
        color: rgba(80, 80, 80, 1);
        font-size: 14px;
        margin-bottom: 16px;
      }
      & > div:nth-child(2) {
        color: rgba(0, 0, 0, 1);
        font-size: 20px;
      }
    }
  }
}
</style>




