<template>
  <div class="top-content">
    <el-select size="small" clearable v-model="ruleForm.orderkind" class="m-2" placeholder="订单类型">
      <el-option v-for="item in orderCategory" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
    <el-select size="small" clearable v-model="ruleForm.orderstatus" class="m-2" placeholder="订单状态">
      <el-option v-for="item in orderStatusList" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
     <el-button size="small" @click="searchClick" type="primary">筛选</el-button>
  </div>
</template>

<script lang="js">
import { emit } from 'process'
import { defineComponent, reactive, ref, watch } from 'vue'
import { orderCategory, orderStatusList } from '../config'
export default defineComponent({
  props: {

  },
  components: {

  },
  emits:['topContentSearch','searchChange'],
  setup(props, { emit }) {

    const ruleForm = reactive({
      orderkind: '',
      orderstatus: ''
    })

    const searchClick=()=>{
      emit('topContentSearch')
    }

    watch(ruleForm, (n) => {
      emit('searchChange',n)
    }, {
      deep: true
    })

    return {
      orderCategory,
      orderStatusList,
      ruleForm,
      searchClick
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  margin-top: 10px;

  &:deep(.el-select) {
    margin-right: 10px;
  }
}
</style>




