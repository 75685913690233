<template>
  <div class="page-content">
    <hy-table :align="align" :isBorder="isBorder" @cellDblclick="cellDblclick" :listData="dataList"
      :listCount="dataCount" v-bind="contentTableConfig" v-model:page="pageInfo" @selectionChange="selectionChange"
      @tableCellClick="tableCellClick" :isShowTooltip="isShowTooltip">
      <!-- 在page-content中动态插入剩余的插槽 -->
      <template v-for="item in otherPropSlots" :key="item.prop" #[item.slotName]="scope">
        <template v-if="item.slotName">
          <slot :name="item.slotName" :row="scope.row"></slot>
        </template>
      </template>
    </hy-table>
  </div>
</template>

<script lang="js">
import { defineComponent, computed, ref, watch, onUnmounted } from 'vue'
import HyTable from '@/base-ui/table'
import { useStore } from 'vuex'
import emitter from '@/utils/eventbus'
export default defineComponent({
  components: {
    HyTable
  },
  props: {
    align: {
      type: String,
      default: 'left'
    },
    isBorder: {
      type: Boolean,
      default: false
    },
    isShowTooltip: {
      type: Boolean,
      default: true
    },
    contentTableConfig: {
      type: Object,
      require: true
    },
    pageName: {
      type: String,
      required: true
    },
    category: {
      type: String,
      default: ''
    },
    isButtonStyle: {
      type: Boolean,
      default: false
    },
    queryInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  emits: ['newBtnClick', 'editBtnClick', 'selectionChange', 'tableCellClick', 'cellDblclick'],
  setup(props, { emit }) {
    const storeCategory = ref(props.category)
    const store = useStore()

    // 1.双向绑定pageInfo
    const pageInfo = ref({ currentPage: 1, pageSize: 10 })
    watch(pageInfo, () => getPageData())


    // 2.发送网络请求
    const getPageData = (queryInfo = {}) => {
      store.dispatch(`${storeCategory.value}/get${props.pageName}ListAction`, {
        pageName: props.pageName,
        page: pageInfo.value.currentPage,
        pagesize: pageInfo.value.pageSize,
        ...props.queryInfo
      })
    }
    getPageData()

    // 3.从vuex中获取数据
    const dataList = computed(() =>
      store.getters[`${storeCategory.value}/pageListData`](props.pageName)
    )
    const dataCount = computed(() =>
      store.getters[`${storeCategory.value}/pageListCount`](props.pageName)
    )


    // 4.获取其他的动态插槽名称
    const otherPropSlots = props.contentTableConfig?.propList.filter(
      (item) => {
        if (!item.slotName) return false
        return true
      }
    )

    // 5.删除/编辑/新建操作
    const handleDeleteClick = (item) => {

    }

    const handleEditClick = (item) => {

    }

    const cellDblclick = (item) => {
      emit('cellDblclick', item)
    }

    const selectionChange = (value) => {
      emit('selectionChange', value)
    }

    const tableCellClick = (value) => {
      emit('tableCellClick', value)
    }

    if (props.pageName == 'shop') {
      emitter.on('refreshShopListPage', () => {
        getPageData()
      })
    }


    if (props.pageName == 'goodsInfo') {
      emitter.on('refreshGoodsListPage', () => {
        getPageData()
      })
    }

    emitter.on('refreshServeListPageContent', () => {
      getPageData()
    })


    onUnmounted(() => {
      emitter.off('refreshStaffListPage')
      emitter.off('refreshServeListPageContent')
      emitter.off('refreshShopListPageContent')
      emitter.off('refreshGoodsListPage')
      emitter.off('refreshShopListPage')

    })




    return {
      storeCategory,
      dataList,
      getPageData,
      dataCount,
      pageInfo,
      otherPropSlots,
      handleDeleteClick,
      handleEditClick,
      selectionChange,
      tableCellClick,
      cellDblclick
    }
  }
})
</script>

<style scoped>
.page-content {}
</style>
