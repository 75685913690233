<template>
  <div class="card-wrap">
    <div :style="{ backgroundColor: item.cardBgcolor }" class="card-item">
      <div @mouseenter="handleCardItemClick" class="card-item-content">
        <div class="top">
          <div class="name">
            <div class="card-name">{{ item.cardName }}</div>
            <div class="card-type">
              <template v-if="item.type == 3"> 有限次卡 </template>
              <template v-if="item.type == 4"> 不限次卡 </template>
              <template v-if="item.type == 5"> 储值卡 </template>
              <template v-if="item.type == 6"> 折扣卡 </template>
            </div>
          </div>
        </div>
        <div class="center">
          <div v-if="item.type == 3 || item.type == 4" class="yu-e">
            <div class="benjni">剩余</div>
            <div>{{ item.serveCount }}次</div>
          </div>
          <div v-if="item.type == 5 || item.type == 6" class="yu-e">
            <div class="benjni">本金余额</div>
            <div class="benjni">{{ (item.cardCash).toFixed(2) }}</div>
          </div>
          <div v-if="item.type == 5" class="yu-e">
            <div class="benjni">赠金余额</div>
            <div class="benjni">{{ (item.cardCashProm).toFixed(2) }}</div>
          </div>
        </div>
        <div class="bottom">
          <div class="left">{{ dayjs(item.expireTime).format("YYYY-MM-DD") }}前有效</div>
		      <div class="right" v-if="item.userCardStatus == 1">使用中</div>
          <div class="right" v-if="item.userCardStatus == 2">已退款</div>
          <div class="right" v-if="item.userCardStatus == 3">已过期（可用）</div>
          <div class="right" v-if="item.userCardStatus == 4">已过期（不可用）</div>
          <div class="right" v-if="item.userCardStatus == 5">已用完</div>
        </div>
      </div>
      <div @mouseleave.stop="hadleCardLeave" v-show="isShowMask" class="mask">
        <div class="bottom-mask"></div>
        <div class="top-mask">
          <!-- <el-button @click="handleCardDetailClick(item.cardKind)" size="small" plain
            >详情</el-button
          > -->
          <el-button @click="handleCardConsumeClick(item)" size="small" plain
            >明细</el-button
          >
          <!-- <el-button @click="handleUpdateYue" v-if="item.type == 5" size="small" plain
            >修改余额</el-button
          > -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import dayjs from 'dayjs'

export default defineComponent({
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {

  },
  setup(props, { emit }) {
    const isShowMask = ref(false)

    const handleCardItemClick = () => {
      // console.log(1);
      isShowMask.value = true
    }

    const detailDialogVisible = ref(false)
    const changeDetailDialogVisible = flag => {
      detailDialogVisible.value = flag
    }

    const hadleCardLeave = () => {
      // console.log(2);
      isShowMask.value = false
    }


    const currentCardId = ref('')
    const handleCardDetailClick = cid => {
      emit('cardDetailClick', cid)
      // currentCardId.value = cid
      // detailDialogVisible.value = true

    }

    const handleCardConsumeClick=item=>{
      // console.log(item);
      emit('handleCardConsumeClick',{
        cardNo:item.cardNo,
        kindId:item.type
      })
    }

    const handleUpdateYue=()=>{
      emit('handleUpdateYue', props.item)
    }


    return {
      handleCardConsumeClick,
      handleUpdateYue,
      detailDialogVisible,
      isShowMask,
      handleCardItemClick,
      dayjs,
      handleCardDetailClick,
      currentCardId,
      hadleCardLeave,
      changeDetailDialogVisible
    }

  }
})
</script>

<style scoped lang="less">
.card-wrap {
  display: flex;
  flex-wrap: wrap;

  .card-item {
    min-width: 260px;
    width: 20%;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 0, 0, 1);
    border-radius: 5px;
    font-size: 14px;
    overflow: hidden;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    margin: 20px 10px;
    min-height: 126px;
    position: relative;
    cursor: pointer;

    .card-item-content {
      width: 100%;
      height: 100%;
      padding: 20px;

      .top {
        .name {
          display: flex;
          align-items: center;

          .card-name {
            color: rgba(255, 255, 255, 1);
            font-size: 14px;
            font-weight: bold;
            margin-right: 10px;
          }

          .card-type {
            border-radius: 5px;
            font-size: 12px;
            border: 1px solid rgba(255, 255, 255, 1);
            background-color: rgba(153, 153, 153, 0);
            padding: 2px 4px;
          }
        }
      }

      .center {
        display: flex;
        // justify-content: space-between;
        margin-top: 6px;

        .yu-e {
          margin-right: 20px;
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 20px;
        width: 85%;
        font-size: 12px;
      }
    }

    .mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      & > div {
        position: absolute;
      }

      .bottom-mask {
        width: 100%;
        height: 100%;
      }

      &:deep(.el-button) {
        background: transparent;
        color: white;
      }
    }
  }
}
</style>
