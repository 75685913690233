<template>
  <div class="top-content">
    <el-date-picker
    size="small"
    v-model="ruleForm.date"
    type="daterange"
    range-separator="到"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    value-format="YYYY-MM-DD" />
    <el-button size="small" @click="searchClick" type="primary">筛选</el-button>
    <el-button size="small" @click="clearClick" type="primary">重置</el-button>
  </div>
</template>

<script lang="js">
import { emit } from 'process'
import { defineComponent, reactive, ref, watch } from 'vue'
import { orderCategory, orderStatusList } from '../config'
import dayjs from 'dayjs'
export default defineComponent({
  props: {

  },
  components: {

  },
  emits:['topContentSearch','searchChange','serveContentClearClick'],
  setup(props, { emit }) {

    const ruleForm = reactive({
      date: ''
    })

    const searchClick=()=>{
      emit('topContentSearch')
    }

    const clearClick = () =>{
      ruleForm.date = []
      emit('serveContentClearClick')
    }

    watch(ruleForm, (n) => {
      emit('searchChange',n)
    }, {
      deep: true
    })

    return {
      orderCategory,
      orderStatusList,
      ruleForm,
      searchClick,
      clearClick
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  margin-top: 10px;
  display: flex;
  align-items: center;

  &:deep(.el-button ) {
    margin-left: 10px;
  }
  &:deep(.el-range-editor--small.el-input__inner) {
    height: 34px !important;
  }

}
</style>




