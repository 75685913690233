export const rankProps = {
  value: 'lvId',
  label: 'levelName',
  checkStrictly: true
}

export const genderList = [
  {
    label: '男',
    value: '1'
  },
  {
    label: '女',
    value: '2'
  }
]

export const rules = {
  realName: [{ required: true, message: '输入姓名', trigger: 'blur' }]
  // birthDay:[{  message: '选择生日', trigger: 'blur' }],
  // shopId:[{  message: '选择门店', trigger: 'blur' }],
}
