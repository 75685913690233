<template>
  <div class="coupon-wrap">
    <template :key="item" v-for="item in couponList">
      <el-popover
          ref="popoverRef"
          v-model:visible="popoverVisible"
          placement="bottom"
          trigger="hover"
          width="260px"
        >
        <div>可用周期：{{ item.effectiveWeekType }}<br /><br />可用时段：{{ item.effectivePeriodType }}<br /><br />适用门店：{{ item.shopName||'全部门店' }}<br /><br /> 获取时间：{{ item.createTime||'-' }}</div>
        <template #reference>
          <div class="coupon-item">
            <div class="top">
              <div class="left">
                <!-- <div class="c-name">{{ item.couponName }}</div> -->
                <div class="h-name" v-if="item.type==1">现金券</div>
                <div class="h-name" v-if="item.type==2">折扣券</div>
                <div class="h-name" v-if="item.type==3">兑换券</div>
              </div>
              <div class="right">
                <div class="c-name">{{ item.name }}</div>
                <!-- <div class="y-title">优惠金额</div>
                <div class="y-price">{{ (item.promPrice / 100).toFixed(2) }}</div> -->
              </div>
            </div>
            <div class="middel">
              <div class="y-price" v-if="item.type==1">{{ (item.discount).toFixed(2) }}</div>
              <div class="y-price" v-if="item.type==2">{{ (item.discount)/10 }}折</div>
              <div class="y-price" v-if="item.type==3">{{ item.serveName }}</div>
            </div>

            <div class="bottom">
              <div class="use-time">
                <template v-if="item.validityType == 1">
                  <!-- {{ item.startDate + "/" + item.endDate }} -->
                  <div style="margin-bottom: 5px;">开始时间：{{ item.startDate }}</div>
                  <div>到期时间：{{ item.endDate }}</div>
                </template>
                <template v-else>
                  <template v-if="item.validityType == 2">
                    领取当天后{{ item.days }}天有效
                  </template>
                  <template v-else> 领取次日后{{ item.days }}天有效 </template>
                </template>
              </div>
            </div>
            <div class="bottoms">
              <div class="state">
                <p v-if="item.status == 1">未使用</p>
                <p v-if="item.status == 2">已使用</p>
                <p v-if="item.status == 3">已过期</p>
                <p v-if="item.status == 4">已作废</p>
                <p v-if="item.status == 5">待领取</p>
              </div>
              <template v-if="!item.hasThreshold"> 无门槛 </template>
              <template v-else>订单满 {{ item.thresholdPrice }}元可用</template>
            </div>
          </div>
        </template>
      </el-popover>
    </template>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {
    couponList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.coupon-wrap {
  display: flex;
  flex-wrap: wrap;

  .coupon-item {
    min-width: 260px;
    padding: 15px 20px;
    width: 20%;
    border-radius: 10px;
    font-size: 14px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    color: #003078;
    background: url('https://yizhige.oss-cn-shanghai.aliyuncs.com/yizhige/46c78c78-fd06-4bbe-9410-b4d1e168d3b4.png') no-repeat;
    background-size: 100% 100%;
    margin: 20px 10px;
    min-height: 136px;
    position: relative;
    .top {
      color: #003078;
      // font-size: 16.5px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      // align-items: center;
      .left {
        .c-name {
          font-size: 16.5px;
          margin-bottom: 10px;
        }
        .h-name {
          font-size: 14px;
          font-weight: 400;
          color: #003078;
        }
      }
      .right {
        .c-name {
          font-size: 12px;
          margin-bottom: 8px;
        }
        .y-title {
          font-size: 12px;
          margin-bottom: 12px;
          color: #003078;
        }
        
      }
    }
    .middel{
      //  margin-top: 10px;
      .y-price {
          font-size: 17px;
          font-weight: 500;
        }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      bottom: 38px;
      width: 85%;
      color: #003078;
      font-size: 12px;
      
    }
    .bottoms{
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      bottom: 10px;
      width: 85%;
      color: #003078;
      font-size: 12px;
      .state {
        display: inline-block;
        padding: 2px 6px;
        border: 1px solid #003078;
        border-radius: 14px;
      }
    }
  }
}
</style>
