<template>
  <div class="customer-detail">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="customer-detail-content">
      <div class="left">
        <base-info></base-info>
      </div>
      <div class="right">
        <order></order>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import BaseInfo from './cpns/base-info/base-info.vue'
import Order from './cpns/order/order.vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
export default defineComponent({
  props: {

  },
  components: {
    BaseInfo,
    Order,
    Breadcrumb
  },
  setup() {


    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.customer-detail {
  .customer-detail-content {
    margin: 20px;
    padding: 20px;
    background-color: #fff;
    display: flex;
    min-width: 980px;

    .left {
      flex: 4;
      min-width: 260px;
    }

    .right {
      margin-left: 20px;
      flex: 14;
      min-width: 700px;
    }
  }
}
</style>




