<template>
  <div class="xiaoshoue">
    <div id="card" :style="{ width: '100%', height: '400px' }"></div>
    <div class="title">卡项购买类型</div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onMounted, nextTick,watch } from 'vue'
import * as echarts from 'echarts'

export default defineComponent({
  props: {
    dataList:{
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {

  },
  setup(props,{ emit }) {
    const data = ref([])
    watch(() => props.dataList, (newValue) => {
      if (newValue) {
        newValue.map(item=>{
          item.value = item.count
        })
        data.value = newValue
        nextTick(()=>{
          getData()
        })
      }
    }, {
      deep: true,
      immediate:true

    })
    const getData = () =>{
      let leftChart = echarts.init(document.getElementById("card"));
      leftChart.setOption(
        {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            show: false
          },
          series: [
            {
              name: '卡项购买',
              type: 'pie',
              radius: ['40%', '60%'],
              avoidLabelOverlap: true,
              itemStyle: {
                borderRadius: 5,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                alignTo: 'edge',
                formatter: '{name|{b}}\n{time|{c}次}',
                minMargin: 5,
                edgeDistance: 10,
                lineHeight: 15,
                bleedMargin: 10,
                rich: {
                  time: {
                    fontSize: 10,
                    color: '#999'
                  }
                }
              },
              labelLine: {
                length: 15,
                length2: 0,
                maxSurfaceAngle: 80
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '16',
                  fontWeight: 'bold'
                }
              },
              data: data.value
            }
          ],
          graphic: { // 无数据显示设置
            type: 'text', // 类型：文本
            left: 'center',
            top: 'middle',
            silent: true, // 不响应事件
            invisible: data.value.length > 0, // 有数据就隐藏
            style: {
              fill: '#9d9d9d',
              fontWeight: 'bold',
              text: '暂无数据',
              fontFamily: 'Microsoft YaHei',
              fontSize: '25'
            }
          },
        }
      )
    }

    return {

    }

  }
})
</script>

<style scoped lang="less">
.xiaoshoue {
  margin: 0 auto ;
  // background-color: #fff;
  width: 500px;
  position: relative;

  .title {
    // line-height: 40px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  #card{
    margin: 0 auto;
  }
}
</style>




