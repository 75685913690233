<template>
  <div class="dialog-form">
    <el-dialog v-if="isShow" v-model="isShow" title="更新储值卡余额" width="30%">
      <el-form
        label-position="left"
        :rules="rules"
        ref="ruleFormRef"
        :model="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="储值卡本金:" prop="cash">
          <el-input v-model="ruleForm.cash" placeholder="本金" />
        </el-form-item>
        <el-form-item label="储值卡赠金:" prop="cashProm">
          <el-input v-model="ruleForm.cashProm" placeholder="赠金" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" @click="saveInfoClick(ruleFormRef)" type="primary"
          >保存</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import { rankProps, genderList } from './config'
import dayjs from 'dayjs'
import { tips } from '@/utils/tips'
import { rules } from './config'
import { useRoute } from 'vue-router'
import { updateChuzhiBalance } from '@/service/main/base'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    card: {
      type: Number,
      default: -1
    }
  },
  components: {


  },
  emits: ['changeDialogVisible', 'refreshUserInfo'],
  setup(props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const isShow = ref(props.dialogVisible)

    watch(() => props.dialogVisible, (newValue) => {
      isShow.value = newValue
    }, {
      deep: true
    })

    watch(isShow, (newValue) => {
      emit('changeDialogVisible', newValue)
    })

    const ruleForm = reactive({
      cardId: "",
      cash: "",
      cashProm: "",
    })


    const ruleFormRef = ref()
    const saveInfoClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          ruleForm.cash = ruleForm.cash * 100
          ruleForm.cashProm = ruleForm.cashProm * 100
          ruleForm.cardId = props.card.id

          let paramsArr = Object.values({ ...ruleForm })
          const res = await updateChuzhiBalance(...paramsArr)
          if (res.code == 0) {
            emit('refreshCard')
            ElMessage({
              message: '操作成功!',
              type: 'success',
            })
          }
          emit('changeDialogVisible', false)

          // console.log('submit!')
        } else {
          // console.log('error submit!', fields)
        }
      })
    }

    const initPage = () => {
      let item = props.card
      ruleForm.cash = item.cardCash/100
      ruleForm.cashProm = item.cardCashProm /100
      // console.log(props.card);
    }
    initPage()


    return {
      ruleFormRef,
      isShow,
      COMPANY_ID,
      ruleForm,
      rules,
      saveInfoClick


    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  &:deep(.el-form) {
    .el-input,
    .el-select {
      width: 100%;
    }
  }

  &:deep(.el-form-item__label) {
    font-size: 12px;
  }

  .use-time {
    line-height: 45px;
    background-color: rgb(224, 241, 240);
    padding: 0 10px;
  }

  .handsel {
    .title {
      margin: 20px 0 10px 0;
      color: rgb(181, 181, 181);
    }

    .item {
      display: flex;
      justify-content: space-between;
      line-height: 40px;
    }
  }

  &:deep(.el-scrollbar) {
    padding-right: 20px;
  }
}
</style>
