<template>
  <div class="order-list">
    <order-tabel :dataTotal="dataTotal" v-model:page="pageInfo"
      :category="category" :titleList="titleList" :orderList="orderList" @refresh="refresh">
    </order-tabel>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'
import PageContent from '@/components/page-content'
import OrderTabel from '@/components/order-tabel/order-tabel.vue'
import { useState } from '@/hooks'
export default defineComponent({
  props: {
    titleList: {
      type: Array,
      default() {
        return []
      }
    },
    dataTotal: {
      type: Number,
      default: 0
    },
    orderList: {
      type: Array,
      default() {
        return []
      }
    },
    category: {
      type: Number,
      default: 0
    }


  },
  components: {
    PageContent,
    OrderTabel
  },
  emits: ['refresh'],
  setup(props, { emit }) {

    const pageInfo = ref({ currentPage: 1, pageSize: 10 })
    watch(pageInfo, (newValue) => {
      // console.log(newValue);
    }, {
      deep: true
    })
    const refresh =()=>{
      emit('refresh')
    }
    return {
      pageInfo,
      refresh
    }

  }
})
</script>

<style scoped lang="less">
.order-list {}
</style>




