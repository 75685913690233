<template>
  <div class="card-detail-dialog">
    <page-dialog
      :width="25"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
      :title="(cardDetail && cardDetail.cardName) ?? ''"
    >
      <div class="card-detail">
        <div class="title">购买权益:</div>
        <div class="list">
          <template :key="item" v-for="item in cardServeList">
            <div class="item">
              <div class="name">
                -
                <div>{{ item.serveName }}</div>
                <div>x{{ item.qty }}</div>
              </div>
              <div class="price">¥{{ (item.avaragePrice / 100) * item.qty }}</div>
            </div>
          </template>
        </div>
        <div class="title">赠送权益:</div>
        <div class="list">
          <template :key="item" v-for="item in cardHandselList.giftGoodList">
            <div class="item">
              <div class="name">
                -
                <div>{{ item.goodName }}</div>
                <div>x{{ item.qty }}</div>
              </div>
              <div class="price">¥{{ (item.avaragePrice / 100) * item.qty }}</div>
            </div>
          </template>
        </div>
        <div class="list">
          <template :key="item" v-for="item in cardHandselList.giftServeList">
            <div class="item">
              <div class="name">
                -
                <div>{{ item.serveName }}</div>
                <div>x{{ item.qty }}</div>
              </div>
              <div class="price">¥{{ (item.avaragePrice / 100) * item.qty }}</div>
            </div>
          </template>
        </div>
        <div class="handsel-total">
          <div class="item">
            <div class="name">-赠送金额</div>
            <div class="price">¥{{ totalHandselPrice / 100 }}</div>
          </div>
        </div>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
import { useState } from '@/hooks'
import { nextTick } from 'process'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    cardId: {
      type: Number,
      default: 0
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const initPage = () => {
      // console.log('请求');
      store.dispatch('card/getCardDetailAction2', {
        companyId: COMPANY_ID,
        cardId: props.cardId
      })

      store.dispatch('card/getCardServeListAction', [props.cardId, COMPANY_ID])
      store.dispatch('card/getCardHandselListAction', [props.cardId, COMPANY_ID])
    }

    watch(() => props.cardId, (n) => {
      // console.log(123);
      initPage()
    },
      {
        deep: true,
        immediate: true
      }
    )


    const cardHandselList = computed(() => store.state.card.cardHandselList)
    const cardDetail = computed(() => store.state.card.cardDetail)
    const cardServeList = computed(() => store.state.card.cardServeList)

    const totalHandselPrice = ref('')
    watch(cardHandselList, (n) => {
      let giftGoodList = n.giftGoodList
      let giftServeList = n.giftServeList
      let sum = 0
      giftGoodList.forEach(item => {
        sum += Number((item.avaragePrice) * (item.qty))
      })

      giftServeList.forEach(item => {
        sum += Number((item.avaragePrice) * (item.qty))
      })

      totalHandselPrice.value = sum
    })






    return {
      changeDialogVisible,
      cardHandselList,
      cardDetail,
      cardServeList,
      totalHandselPrice
    }

  }
})
</script>

<style scoped lang="less">
.card-detail-dialog {
  &:deep(.el-dialog__header) {
    border: 1px solid #ccc;
  }

  .card-detail {
    top: -20px;
    position: relative;

    .title {
      font-weight: 550;
      margin: 16px 0;
    }

    .handsel-total {
      margin-top: 10px;
    }

    .list,
    .handsel-total {
      .item {
        display: flex;
        justify-content: space-between;
        margin: 8 0;

        .price {
          color: #ff6600;
          padding: 5px;
          font-size: 12px;
        }

        .name {
          display: flex;
          padding: 5px;
          font-size: 12px;

          & > div:nth-child(1) {
            margin-right: 20px;
          }
        }
      }
    }
  }
}
</style>
