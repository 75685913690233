<template>
  <div>
    <div class="card">
        <el-card>
            <div class="flex_card" style="margin: 10px auto 40px; border-bottom: 1px solid #eee;padding-bottom: 30px;">
                <div class="card_item">
                    <el-button type="danger" circle size="large">
                        <el-icon><Avatar /></el-icon>
                    </el-button>
                    <div class="right">
                        <el-popover
                        placement="top-start"
                        :width="300"
                        trigger="hover"
                        content="实时展示用户最近10条预约爽约率，最近10条预约的爽约次数➗10"
                        >
                        <template #reference>
                            <p class="plan">爽约率<el-icon><QuestionFilled /></el-icon></p>
                        </template>
                        </el-popover>
                        <div class="count">{{userInfo.breachRate||0}}%</div>
                    </div>
                </div>
                <div class="card_item">
                    <el-button type="success" circle size="large">
                        <el-icon><Avatar /></el-icon>
                    </el-button>
                    <div class="right">
                        <el-popover
                        placement="top-start"
                        :width="300"
                        trigger="hover"
                        content="取消次数/预约次数(近10条预约)"
                        >
                        <template #reference>
                            <p class="plan">取消率<el-icon><QuestionFilled /></el-icon></p>
                        </template>
                        </el-popover>
                        <div class="count">{{userInfo.cancelRate||0}}%</div>
                    </div>
                </div>
                <div class="card_item">
                    <el-button type="warning" circle size="large">
                        <el-icon><Avatar /></el-icon>
                    </el-button>
                    <div class="right">
                        <el-popover
                        placement="top-start"
                        :width="300"
                        trigger="hover"
                        content="迟到次数/预约次数(迟到次数:预约时间<到店时间后10分钟=迟到)(近10条预约)"
                        >
                        <template #reference>
                            <p class="plan">迟到率<el-icon><QuestionFilled /></el-icon></p>
                        </template>
                        </el-popover>
                        <div class="count">{{userInfo.lateRate||0}}%</div>
                    </div>
                </div>
            </div>
            <div>
                <el-date-picker
                v-model="date"
                type="daterange"
                :shortcuts="shortcuts"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                value-format="YYYY-MM-DD"
                @change="changeDate"
                />
            </div>
            <div class="flex_card margin">
                <div class="card_item">
                    <div class="right">
                        <p class="plan">累计预约（次）</p>
                        <div class="count">{{analysisData.appCount?analysisData.appCount:0}}</div>
                    </div>
                </div>
                <div class="card_item">
                    <div class="right">
                        <p class="plan">累计到店（次）</p>
                        <div class="count">{{analysisData.appCompleteCount?analysisData.appCompleteCount:0}}</div>
                    </div>
                </div>
                <div class="card_item">
                    <div class="right">
                        <p class="plan">累计消费金额（元）</p>
                        <div class="count">{{analysisData.payPriceSum?analysisData.payPriceSum:0}}</div>
                    </div>
                </div>
                <div class="card_item">
                    <div class="right">
                        <p class="plan">累计消费项目（个）</p>
                        <div class="count">{{analysisData.appServeCount?analysisData.appServeCount:0}}</div>
                    </div>
                </div>
            </div>
            <el-row :gutter="30">
                <el-col :span="12">
                    <serve class="bingtu" :dataList="analysisData.appServeCountList"></serve>
                </el-col>
                <el-col :span="12">
                    <card class="bingtu" :dataList="analysisData.cardCountList"></card>
                </el-col>
                <el-col :span="12">
                    <shop class="columnar" :dataList="analysisData.shopPayRankingList"></shop>
                </el-col>
                <el-col :span="12">
                    <emp class="columnar" :dataList="analysisData.empAppRankingList"></emp>
                </el-col>
                <el-col :span="12">
                    <frequency class="columnar" :dataList="analysisData.consumerStatisticsList"></frequency>
                </el-col>
                <el-col :span="12">
                    <payType class="columnar" :dataList="analysisData.payWayStatisticsList"></payType>
                </el-col>
            </el-row>
        </el-card>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watchEffect, reactive ,computed, onBeforeMount,watch} from 'vue'
import serve from './bingtu/serve.vue'
import card from './bingtu/card.vue'
import payType from './bingtu/payType.vue'
import shop from './columnar/shop.vue'
import emp from './columnar/emp.vue'
import { useStore } from 'vuex'
import frequency from './columnar/frequency.vue'
import { useRoute } from 'vue-router'
import { getCustomerAnalysis } from '@/service/main/customer'
export default defineComponent({
    components: {
        serve,
        card,
        shop,
        emp,
        frequency,
        payType
    },
    setup (props, { emit }) {
        const route = useRoute()
        const analysisData = ref({})
        const store = useStore()
        const userDetail = computed(() => store.state.customer.userDetail)
        const userInfo= ref({})
        watch(userDetail, (n) => {
            if (n != null) {
                userInfo.value=n
            }
        }, {
            deep: true
        })
        const shortcuts = [
            {
                text: '本月',
                value: () => {
                const end = new Date()
                const start = new Date()
                start.setDate(1);
                return [start, end]
                },
            },
            {
                text: '本季',
                value: () => {
                var oDate = new Date();
                var thisYear = oDate.getFullYear();
                var thisMonth = oDate.getMonth() + 1;
                var n = Math.ceil(thisMonth / 3); // 季度
                var Month = n * 3 - 1;
                var start = new Date(thisYear, Month - 2, 1);
                var end = new Date();
                return [start, end]
                },
            },
            {
                text: '本年',
                value: () => {
                const end = new Date();
                const start = new Date();
                // end.setTime(end.getTime() - 3600 * 1000 * 24)//加上这个就是不包含今天
                start.setMonth(0);
                start.setDate(1);
                return [start, end]
                },
            },
        ]
        const date = ref(null)
        const startDate = ref(null)
        const endDate = ref(null)
        const changeDate = (e) =>{
            if (e) {
                startDate.value = e[0]
                endDate.value = e[1]
            }else{
                startDate.value = null
                endDate.value = null
            }
            getData()
        }
        const getData = async() =>{
            let params = {
                userId:route.query.userId,
                startDate:startDate.value,
                endDate:endDate.value
            }
            let data = await getCustomerAnalysis(params)
            analysisData.value = data.data
            console.log(analysisData.value.appServeCountList);
        }
        getData()
        return {
            shortcuts,
            date,
            changeDate,
            analysisData,
            userInfo
        }
    }
})
</script>

<style lang="less" scoped>
.flex_card{
    display: flex;
    justify-content: space-between;
    text-align: center;
    .card_item{
        width: 33%;
        display: flex;
        justify-content: center;
        align-items: center;
        .right{
            margin-left: 10px;
            height: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &:deep(.el-button){
            width: 50px;
            height: 50px;
        }
        .count{
            font-weight: 500;
        }
    }
}
.margin{
    margin: 30px auto 0;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
}
.bingtu{
    width: 100%;
}
</style>