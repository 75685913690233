<template>
    <div class="xiaoshoue">
      <div id="frequency" :style="{ width: '100%', height: '450px' }"></div>
      <div class="title">消费频次</div>
    </div>
  </template>
  
  <script lang="js">
  import { defineComponent, reactive, ref, onMounted, nextTick,watch } from 'vue'
  import * as echarts from 'echarts'
  
  export default defineComponent({
    props: {
      dataList:{
        type: Array,
        default() {
          return []
        }
      }
    },
    components: {
  
    },
    setup(props,{ emit }) {
      const nameData = ref([])
      const valueData = ref([])
      watch(() => props.dataList, (newValue) => {
        if (newValue) {
            nameData.value=newValue.map(item=>{
                return item.name
            })
            valueData.value=newValue.map(item=>{
                return item.count
            })
            nextTick(()=>{
                getData()
            })
        }
      }, {
        deep: true,
        immediate:true
      })
      const getData = () =>{
        let leftChart = echarts.init(document.getElementById("frequency"));
        leftChart.setOption({
          tooltip: {
              trigger: "axis",
              axisPointer: {
                  type: "cross",
                  crossStyle: {
                      color: "#999",
                  },
              },
          },
          xAxis: {
            type: 'category',
            data: nameData.value
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: "消费次数",
              data: valueData.value,
              type: 'line',
              label:{
                show:true,
                position:'top',
                formatter:function(data){
                  return data.value
                }
              }
            }
          ],
          graphic: { // 无数据显示设置
            type: 'text', // 类型：文本
            left: 'center',
            top: 'middle',
            silent: true, // 不响应事件
            invisible: valueData.value.length > 0, // 有数据就隐藏
            style: {
              fill: '#9d9d9d',
              fontWeight: 'bold',
              text: '暂无数据',
              fontFamily: 'Microsoft YaHei',
              fontSize: '25'
            }
          },
        })
      }
  
      return {
  
      }
  
    }
  })
  </script>
  
  <style scoped lang="less">
  .xiaoshoue {
    margin: 20px auto;
    // background-color: #fff;
    width: 500px;
  
    .title {
      // line-height: 40px;
      font-size: 24px;
      font-weight: 500;
      text-align: center;
    }
    #frequency{
      margin: 0 auto;
    }
  }
  </style>
  
  
  
  
  