<template>
  <div class="order-tabel">
    <div class="title" v-if="category ==0">
      <template :key="index" v-for="(item,index) in titleList">
        <div :style="index==0?'flex:1.6':index==1?'flex:0.6':''">{{ item }}</div>
      </template>
    </div>
    <div class="order-info-list" v-if="category ==0">
      <div v-if="orderList.length > 0">
        <template :key="item.code" v-for="item in orderList">
          <div class="order-info-item">
            <div class="top">
              <div class="left">
                <div class="buy-time">完成时间：{{ item.payTime }}</div>
                <div class="order-num">订单编号：{{ item.code }}</div>
              </div>
              <div @click="handleDerDetailClick(item)" class="right">详情</div>
            </div>
            <div class="bottom">
              <div class="avatar-price-buynum">
                <template v-if="item.type == 1">
                  <template :key="serveAvatar" v-for="serveAvatar in item.itemList">
                    <div class="avatar-price-buynum-item">
                      <div class="avatar" style="flex:2.5;">
                        <div class="serveAvatar-item">
                          <!-- <template> -->
                            <img :src="serveAvatar.productPicture" alt />
                          <!-- </template> -->
                          <!-- <template v-if="item.orderKind == 3">
                            <img src="@/assets/img/main/card/card1.png" alt />
                          </template>
                          <template v-if="item.orderKind == 4">
                            <img src="@/assets/img/main/card/card2.png" alt />
                          </template>
                          <template v-if="item.orderKind == 5">
                            <img src="@/assets/img/main/card/card3.png" alt />
                          </template>
                          <template v-if="item.orderKind == 7">
                            <img src="@/assets/img/main/card/card4.png" alt />
                          </template>
                          <template v-if="item.orderKind == 8">
                            <img src="@/assets/img/main/card/card5.png" alt />
                          </template> -->

                          <div class="goods_name">
                            <div class="itemName">{{ serveAvatar.productName }}</div>
                            <div>
                              <!-- <template v-if="item.orderKind == 1 || item.orderKind == 2"> -->
                                <span class="el-tag el-tag--mini el-tag--plain"
                                  >服务</span
                                >
                              <!-- </template> -->
                              <!-- <template v-if="item.orderKind == 3 || item.orderKind == 4">
                                <span
                                  class="el-tag el-tag--success el-tag--mini el-tag--plain"
                                  >次卡</span
                                >
                              </template>
                              <template v-if="item.orderKind == 5">
                                <span
                                  class="el-tag el-tag--info el-tag--mini el-tag--plain"
                                  >储值卡</span
                                >
                              </template>
                              <template v-if="item.orderKind == 7">
                                <span
                                  class="el-tag el-tag--danger el-tag--mini el-tag--plain"
                                >
                                  余额充值</span
                                >
                              </template>
                              <template v-if="item.orderKind == 6">
                                <span
                                  class="el-tag el-tag--warning el-tag--mini el-tag--plain"
                                  >商品</span
                                >
                              </template> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="price">
                        <div>¥{{ serveAvatar.price.toFixed(2) }}</div>
                      </div>
                      <div class="but-num">
                        <div>
                          x{{ serveAvatar.count }}
                        </div>
                      </div>
                    </div>
                  </template>
                </template>

                <template v-else>
                  <template
                    :key="serveAvatar"
                    v-for="(serveAvatar, indez) in item.itemList"
                  >
                    <div class="avatar-price-buynum-item">
                      <div class="avatar" style="flex:2.5;">
                        <div class="serveAvatar-item">
                          <!-- <template v-if="serveAvatar.productType == 3">
                            <img src="@/assets/img/main/card/card1.png" alt />
                          </template>
                          <template v-if="serveAvatar.productType == 4">
                            <img src="@/assets/img/main/card/card2.png" alt />
                          </template>
                          <template v-if="serveAvatar.productType == 5">
                            <img src="@/assets/img/main/card/card3.png" alt />
                          </template>
                          <template v-if="serveAvatar.productType == 7">
                            <img src="@/assets/img/main/card/card4.png" alt />
                          </template> -->
                          <!-- <template> -->
                            <img src="@/assets/img/main/card/card5.png" alt />
                          <!-- </template> -->
                          <div class="goods_name">
                            <div class="itemName">{{ serveAvatar.productName }}</div>
                            <div>
                              <template v-if="item.type == 1">
                                <span class="el-tag el-tag--mini el-tag--plain"
                                  >服务</span
                                >
                              </template>
                              <template v-else>
                                <span
                                  class="el-tag el-tag--success el-tag--mini el-tag--plain"
                                  >购卡</span
                                >
                              </template>
                              <!-- <template v-if="item.orderKind == 5">
                                <span
                                  class="el-tag el-tag--info el-tag--mini el-tag--plain"
                                  >储值卡</span
                                >
                              </template>
                              <template v-if="item.orderKind == 7">
                                <span
                                  class="el-tag el-tag--danger el-tag--mini el-tag--plain"
                                >
                                  余额充值</span
                                >
                              </template>
                              <template v-if="item.orderKind == 6">
                                <span
                                  class="el-tag el-tag--warning el-tag--mini el-tag--plain"
                                  >商品</span
                                >
                              </template>
                              <template v-if="item.orderKind == 8">
                                <span
                                  class="el-tag el-tag--warning el-tag--mini"
                                  >折扣卡</span
                                >
                              </template> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="price">
                        <div>¥{{ (serveAvatar.price).toFixed(2) }}</div>
                      </div>
                      <div class="but-num">
                        <div>x{{serveAvatar.count }}</div>
                      </div>
                    </div>
                  </template>
                </template>
              </div>
              <div>
                <template v-if="item.isPoint">
                  <span class="el-tag el-tag--success el-tag--small el-tag--dark"
                    >是</span
                  >
                </template>
                <template v-else>
                  <span class="el-tag el-tag--info el-tag--small el-tag--dark">否</span>
                </template>
              </div>
              <div class="total-price">
                  <div>¥{{ item.price.toFixed(2) }}</div>
              </div>
              <div class="buy-shop">
                <div>{{ item.shopName }}</div>
                <!-- <template v-if="item.roomInfo != null">
                  <div>
                    {{ item.roomInfo.roomNo }}<span>&</span>{{ item.roomInfo.bedId }}
                  </div>
                </template> -->
              </div>
              <div @click="handleUserClick(item)" class="user-name">
                {{ item.saleName }}
              </div>

              <div class="state">
                <!-- <template v-if="item.status == 1"> -->
                  <template v-if="item.payStatus == 1">
                    <span class="el-tag el-tag--warning el-tag--small el-tag--dark"
                      >待支付</span
                    >
                  </template>
                  <template v-if="item.payStatus == 2">
                    <span class="el-tag el-tag--success el-tag--small el-tag--dark"
                      >已支付</span
                    >
                  </template>
                <!-- </template> -->
                <!-- <template v-if="item.status == 2">
                  <span class="el-tag el-tag--info el-tag--small el-tag--dark"
                    >已取消</span
                  >
                </template> -->
                <template v-if="item.payStatus == 3">
                  <span class="el-tag el-tag--success el-tag--small el-tag--dark"
                    >已支付</span
                  >
                  <p style="marginTop:6px">（退款成功）</p>
                  <!-- <span class="el-tag el-tag--info el-tag--small el-tag--dark"
                    >已退款</span
                  > -->
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-else class="empty">
        <el-empty description="暂无相关数据"></el-empty>
      </div>
    </div>
    <div v-if="category == 1">
      <el-table :data="orderList" border style="width: 100%">
      <el-table-column align="center" prop="orderNo" label="订单编号" width="205" />
      <el-table-column align="center" prop="appTime" label="预约时间" width="165" />
      <el-table-column align="center" prop="userName" label="用户信息" width="120" />
      <el-table-column align="center" prop="evaluateTime" label="评价时间" width="165" />
      <el-table-column align="center" prop="shopName" label="评价门店" width="170" />
      <el-table-column align="center" prop="shopScore" label="评价分值" width="150">
        <template #default="scope">
          <el-rate disabled v-model="scope.row.shopScore" size="large" />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="shopEvaluate" label="评价内容" width="250" />
      <el-table-column align="center" prop="empName" label="评价技师" width="120" />
      <el-table-column align="center" prop="score" label="评价分值" width="150">
        <template #default="scope">
          <el-rate disabled v-model="scope.row.score" size="large" />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="empEvaluate" label="评价内容" width="250" />
      <el-table-column align="center" prop="itemList" label="评价服务" width="190">
        <template #default="scope">
          <div class="borders" v-for="items in scope.row.itemList" :key="items">
            <p>{{ items.serveName }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="itemList" label="评价分值" width="150">
        <template #default="scope">
          <div class="borders" v-for="items in scope.row.itemList" :key="items">
            <p><el-rate disabled v-model="scope.row.score" size="large" /></p>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="itemList" label="评价内容" width="250">
        <template #default="scope">
          <div class="borders" v-for="items in scope.row.itemList" :key="items">
            <p>{{ items.content }}</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="pictureList" label="图片" width="200">
        <template #default="scope">
          <el-image
          v-for="items in scope.row.pictureList" 
          :key="items" 
          style="width: 50px; height: 50px;margin-right: 5px;"
          :src="items"
          :zoom-rate="1.2"
          :max-scale="7"
          :min-scale="0.2"
          :preview-src-list="scope.row.pictureList"
          :initial-index="4"
          fit="cover"
        />
        </template>
      </el-table-column>
      <el-table-column prop="date" label="操作" align="center" fixed="right" width="150px" v-has="'evaluate:edit'">
          <template #default="scope">
            <el-switch v-model="scope.row.display" @change="handlleSwichClick(scope.row)" />
          </template>
        </el-table-column>
    </el-table>
    </div>
    <div v-if="category == 2">
      <el-table :data="orderList" border style="width: 100%">
        <el-table-column align="center" type="index" label="序号" width="80" />
        <el-table-column align="center" prop="payTime" label="打赏时间" width="165" />
        <el-table-column align="center" prop="shopName" label="打赏门店" width="160" />
        <el-table-column align="center" prop="orderNo" label="订单编号" width="205" />
        <el-table-column align="center" prop="appTime" label="预约时间" width="165" />
        <el-table-column align="center" prop="userName" label="用户信息" width="150" />
        <el-table-column align="center" prop="ruleName" label="打赏类型" width="150" />
        <el-table-column align="center" prop="price" label="打赏金额" width="120" />
        <el-table-column align="center" prop="empName" label="打赏员工" width="120" />
        <el-table-column align="center" prop="remark" label="用户留言" width="180" />
      </el-table>
    </div>
    <div v-if="category == 3">
      <el-table :data="orderList" border style="width: 100%">
        <el-table-column align="center" prop="orderNo" label="使用订单号" width="150" />
        <el-table-column align="center" prop="couponNo" label="券号" width="165" />
        <el-table-column align="center" prop="couponName" label="券名称" width="160" />
        <el-table-column align="center" prop="shopName" label="适用门店" width="205" />
        <el-table-column align="center" prop="sourcePrice" label="服务原价" width="165" />
        <el-table-column align="center" prop="discountPrice" label="优惠金额" width="150" />
        <el-table-column align="center" prop="payPrice" label="购买金额" width="150" />
        <el-table-column align="center" prop="tripartiteCouponNo" label="平台券号" width="120" />
        <el-table-column align="center" prop="status" label="使用状态" width="120">
          <template #default="scope">
            <p v-if="scope.row.status==1">未使用</p>
            <p v-if="scope.row.status==2">已使用</p>
            <p v-if="scope.row.status==3">已过期</p>
            <p v-if="scope.row.status==4">已作废</p>
            <p v-if="scope.row.status==5">待领取</p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="createName" label="兑换操作人" width="180" />
      </el-table>
    </div>
    <!-- <div class="el-pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page.currentPage" :page-size="page.pageSize" :page-sizes="[10, 20, 30]"
        layout="total, sizes, prev, pager, next, jumper" :total="dataTotal"></el-pagination>
    </div> -->
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { UPLOAD_BASE_URL } from '@/common/constant'
import { useRouter } from 'vue-router'
import { displayEvaluate } from '@/service/main/order'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {
    page: {
      type: Object,
      default: () => ({ currentPage: 1, pageSize: 10 })
    },
    titleList: {
      type: Array,
      default() {
        return []
      }
    },
    dataTotal: {
      type: Number,
      default: 0
    },
    orderList: {
      type: Array,
      default() {
        return []
      }
    },
    /*
    category
    0 订单
    1 跟进
    2 评价
    */
    category: {
      type: Number,
      default: 0
    }
  },
  components: {

  },
  emits: ['update:page','refresh'],
  setup(props, { emit }) {
    const router = useRouter()
    const handleCurrentChange = (currentPage) => {
      emit('update:page', { ...props.page, currentPage })
    }

    const handleSizeChange = (pageSize) => {
      emit('update:page', { ...props.page, pageSize })
    }
    const handlleSwichClick = async (item) => {
      let { id } = item
      const res = await displayEvaluate(id)
      if (res.code == 0) {
        ElMessage({
          message: '操作成功!',
          type: 'success',
        })
        emit('refresh')
      }
    }

    const handleDerDetailClick = item => {
      router.push({
        path: '/main/order/detail',
        query: {
          id: item.id,
          type:item.type
        }
      })
      // console.log(item);
    }

    return {
      handleDerDetailClick,
      handleCurrentChange,
      handleSizeChange,
      UPLOAD_BASE_URL,
      handlleSwichClick
    }

  }
})
</script>

<style scoped lang="less">
.order-tabel {
  margin-top: 20px;
  font-size: 12px;

  .title {
    display: flex;
    color: rgba(80, 80, 80, 1);
    background-color: rgba(239, 239, 239, 1);
    height: 48px;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;

    div {
      flex: 1;
      text-align: left;
    }
  }

  .order-info-list {
    // padding: 0 10px;
    .order-info-item {
      margin: 10px 0;

      .top {
        padding: 0 10px;
        display: flex;
        color: rgba(80, 80, 80, 1);
        background-color: rgba(239, 239, 239, 1);
        font-size: 12px;
        height: 35px;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          color: #666666;

          .buy-time {
            margin-right: 20px;
          }
        }

        .right {
          color: rgba(42, 130, 228, 1);
          font-size: 12px;
          cursor: pointer;
        }
      }

      .bottom {
        display: flex;

        .avatar-price-buynum {
          display: flex;
          flex-direction: column;
          flex: 3;
          align-items: stretch;
          padding-left: 0px;

          .avatar-price-buynum-item {
            border-top: 1px solid var(--borderColor);
            display: flex;
            flex: 1;

            .avatar {
              padding: 0 10px;
            }

            .but-num {
              padding: 0 10px;
            }

            .price {
              div {
                width: 100%;
                padding: 0 10px;
              }
            }

            & > div {
              flex: 1;
            }
          }

          .price,
          .but-num {
            display: flex;
            align-items: center;

            & > div {
              padding: 10px 0;
            }
          }
        }

        .price {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          border-left: 1px solid var(--borderColor);
          border-right: 1px solid var(--borderColor);
          // align-items: center;
          // justify-content: center;
        }

        .price,
        .total-price {
          color: #ff6600;
        }

        .total-price {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .pay-type {
          font-size: 12px;
          margin-top: 2px;
        }

        & > div {
          display: flex;
          flex: 1;
          padding-left: 10px;
          // justify-content: center;
          border-right: 1px solid var(--borderColor);
          border-bottom: 1px solid var(--borderColor);
          align-items: center;
          color: rgba(80, 80, 80, 1);
          font-size: 12px;
          min-height: 55px;
        }

        .avatar {
          display: flex;
          flex-direction: column;
          border-left: 1px solid var(--borderColor);
          align-items: flex-start;

          .serveAvatar-item {
            display: flex;
            padding: 10px 0;
            // border-bottom: 1px solid var(--borderColor) ;
          }

          .serveAvatar-item:last-child {
            border-bottom: none;
          }

          img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
          }
        }

        .goods_name {
          display: flex;
          flex-direction: column;

          .itemName {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .el-pagination {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
  &:deep(.el-table) {
    th.el-table__cell{
      background-color: rgba(239, 239, 239, 1) !important;
      .cell {
        color: rgba(80, 80, 80, 1) !important;
        font-size: 14px !important;
        font-weight: 500 !important;
      }
    }
    .el-table__header-wrapper {
    
    .el-table__cell {

      background-color: rgba(239, 239, 239, 1);
      // padding: 9px 0 !important;
      // border:1px solid rgb(223, 223, 223);

      .cell {
        color: rgba(80, 80, 80, 1) !important;
        font-size: 14px !important;
        font-weight: 500 !important;
      }
    }

  }
    .cell {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .borders{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #ebeef5;
    align-items: center;
    height: 60px;
    // line-height: 40px;
    padding: 10px !important;
    // p{
    //   border-bottom: 1px solid #ebeef5;
    //   line-height: 28px;
    // }
    // p:last-child{
    //   border: none !important;
    // }
  }
  .borders:last-child{
      border: none !important;
  }
}
</style>




