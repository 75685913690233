<template>
  <div class="trade">
    <el-tabs type="card" class="tabs" @tab-click="handleClick">
      <el-tab-pane label="订单">
        <top-content
          @topContentSearch="topContentSearch"
          @searchChange="searchChange"
        ></top-content>
        <order-list
          :category="0"
          :orderList="allOrderList"
          :titleList="titleList"
        ></order-list>
        <div class="pagination">
          <el-pagination
            v-model:currentPage="ruleForm.dingdanPage"
            v-model:page-size="ruleForm.dingdanPagesize"
            :page-sizes="[10, 20, 30, 40]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Number(allOrderListCount)"
            @size-change="handleSizeChange($event, 0)"
            @current-change="handleCurrentChange($event, 0)"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="评价">
        <order-list
          :category="1"
          :orderList="userCommentList"
          @refresh="refresh"
        ></order-list>
        <div class="pagination">
          <el-pagination
            v-model:currentPage="ruleForm.pinglunPage"
            v-model:page-size="ruleForm.pinglunPagesize"
            :page-sizes="[10, 20, 30, 40]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Number(userCommentListCount)"
            @size-change="handleSizeChange($event, 2)"
            @current-change="handleCurrentChange($event, 2)"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="打赏">
        <order-list
          :category="2"
          :orderList="dashangList"
        ></order-list>
        <div class="pagination">
          <el-pagination
            v-model:currentPage="ruleForm.dashangPage"
            v-model:page-size="ruleForm.dashangPagesize"
            :page-sizes="[10, 20, 30, 40]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Number(userCouponListCount)"
            @size-change="handleSizeChange($event, 1)"
            @current-change="handleCurrentChange($event, 1)"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="兑换券订单">
        <order-list
          :category="3"
          :orderList="couponList"
        ></order-list>
        <div class="pagination">
          <el-pagination
            v-model:currentPage="ruleForm.duihuanPage"
            v-model:page-size="ruleForm.duihuanPagesize"
            :page-sizes="[10, 20, 30, 40]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Number(userCouponListCount)"
            @size-change="handleSizeChange($event, 3)"
            @current-change="handleCurrentChange($event, 3)"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed,onBeforeUnmount } from 'vue'
import TopContent from './cpns/top-content/top-content.vue'
import OrderList from './cpns/order-list/order-list.vue'
import { useState } from '@/hooks'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { COMPANY_ID } from '@/common/constant'
import { updateCommentState } from '@/service/main/customer'
import { tips } from '@/utils/tips'

export default defineComponent({
  props: {

  },
  components: {
    TopContent,
    OrderList
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const orderListState = useState('customer', ['tradeOrderList', 'titleList', 'followOrderList', 'commentOrderList'])

    const userId = computed(() => route.query.userId)
    const dashangList = computed(() => store.state.customer.userDashangList)
    const userDashangListCount = computed(() => store.state.customer.userDashangListCount)
    const userCommentListCount = computed(() => store.state.customer.userCommentListCount)
    const userCommentList = computed(() => store.state.customer.userCommentList)
    const couponList = computed(() => store.state.customer.userCouponList)
    const userCouponListCount = computed(() => store.state.customer.userCouponListCount)
    console.log(userCommentList);
    const allOrderListCount = computed(() => store.state.order.allOrderListCount)
    const allOrderList = computed(() => store.state.order.allOrderList)

    const ruleForm = reactive({
      dashangPage: 1,
      pinglunPage: 1,
      dingdanPage: 1,
      duihuanPage: 1,
      dashangPagesize: 10,
      pinglunPagesize: 10,
      dingdanPagesize: 10,
      duihuanPagesize: 10
    })

    const daShangList = () => {
      store.dispatch('customer/getUsetDashangListAction', {
        userId: userId.value,
        current: ruleForm.dashangPage,
        size: ruleForm.dashangPagesize
      })
    }
    daShangList()

    const getCouponList = () => {
      store.dispatch('customer/getUsetCouponListAction', {
        userId: userId.value,
        current: ruleForm.duihuanPage,
        size: ruleForm.duihuanPagesize
      })
    }
    getCouponList()

    const commentList = () => {
      store.dispatch('customer/getUsetCommrntListAction', {
        userId: userId.value,
        current: ruleForm.pinglunPage,
        size: ruleForm.pinglunPagesize
      })
    }
    commentList()
    const currentPage = reactive({
      current: 1,
      size: 10,
      userId:userId.value
    })
    const refresh = () =>{
      commentList()
    }

    const searchChange = item => {
      currentPage.orderType = item.orderkind||undefined
      currentPage.appStatus = item.orderstatus||undefined
    }

    const orderList = () => {
      store.dispatch('order/getOrderListAction',currentPage)
    }
    orderList()

    const topContentSearch = () => {
      orderList()
    }

    const handleCurrentChange = (page, type) => {
      if (type == 1) {
        ruleForm.dashangPage = page
        daShangList()
      } else if (type == 2) {
        ruleForm.pinglunPage = page
        commentList()
      }else if (type == 3) {
        ruleForm.duihuanPage = page
        getCouponList()
      } else {
        currentPage.current = page
        orderList()
      }

    }

    const handleSizeChange = (pagesize, type) => {
      if (type == 1) {
        ruleForm.dashangPagesize = pagesize
        daShangList()
      } else if (type == 2) {
        ruleForm.pinglunPagesize = pagesize
        commentList()
      }else if (type == 3) {
        ruleForm.duihuanPagesize = pagesize
        getCouponList()
      } else {
        currentPage.size = pagesize
        orderList()
      }

    }
    onBeforeUnmount(() => {
      store.commit('order/changeallOrderList', [])
      store.commit('order/changeallOrderListCount', 0)
    });

    return {
      handleSizeChange,
      handleCurrentChange,
      ...orderListState,
      dashangList,
      couponList,
      userCouponListCount,
      userDashangListCount,
      ruleForm,
      userCommentList,
      userCommentListCount,
      COMPANY_ID,
      allOrderListCount,
      allOrderList,
      searchChange,
      topContentSearch,
      refresh
    }

  }
})
</script>

<style scoped lang="less">
.trade {
  margin-top: 30px;
  &:deep(.el-tabs__item) {
    background-color: rgb(229, 229, 229);
  }
  &:deep(.el-tabs__item.is-active) {
    color: rgba(80, 80, 80, 1) !important;
    font-size: 14px;
    border-bottom: 1px solid rgba(228, 231, 237, 1);
    background-color: #fff;
  }

  .pagination {
    text-align: right;
  }
}
</style>
